/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { onroadAppURI } from '../config';
import Spinner from '../components/Spinner';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import RemarkList from '../components/RemarkList';
import { useHeaders } from '../hooks/useHeaders';

const Remarks = () => {
  const [remarks, setRemarks] = useState([]);
  const [fetching, setFetching] = useState(false);
  const headers = useHeaders(true);

  const loadRemarks = async () => {
    setFetching(true);
    const res = await fetch(`${onroadAppURI}protected/remarks`, {
      method: 'GET',
      headers,
    });
    setFetching(false);
    const data = await res.json();
    if (data?.remarks) {
      setRemarks(data.remarks);
    }
  };

  useEffect(() => {
    loadRemarks();
  }, []);

  return (
    <div className="p-4 overflow-scroll overflow-x-hidden h-[calc(100vh-60px)]">
      {fetching ? <Spinner /> : null}
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Loodswezen Opmerkingen</h1>
        <button
          onClick={() => loadRemarks()}
          className="bg-primary-400 text-xs p-2 rounded-md"
        >
          <ArrowPathIcon className="w-4 h-4 float-left mr-2" /> Lijst vernieuwen
        </button>
      </div>

      <RemarkList remarks={remarks} />
    </div>
  );
};

export default Remarks;
