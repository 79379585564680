export const getTodayUTC = () => {
  var now = new Date();
  return Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
};

function isNewDST(UTCDate, hour) {
  if (!hour) {
    hour = 0;
  }
  var UTCDateWithHour = UTCDate + hour * 60 * 60 * 1000;
  var DSTDATES = {
    2016: { start: 1459040400000, end: 1477785600000 },
    2017: { start: 1490490000000, end: 1509235200000 },
    2018: { start: 1521939600000, end: 1540684800000 },
    2019: { start: 1553994000000, end: 1572134400000 },
    2020: { start: 1585443600000, end: 1603584000000 },
    2021: { start: 1616893200000, end: 1635638400000 },
    2022: { start: 1648342800000, end: 1667088000000 },
    2023: { start: 1679792400000, end: 1698537600000 },
    2024: { start: 1711846800000, end: 1729987200000 },
    2025: { start: 1743296400000, end: 1761436800000 },
    2026: { start: 1774746000000, end: 1792886400000 },
    2027: { start: 1806195600000, end: 1824940800000 },
    2028: { start: 1837645200000, end: 1856390400000 },
    2029: { start: 1869094800000, end: 1887840000000 },
    2030: { start: 1901149200000, end: 1919289600000 },
    2031: { start: 1932598800000, end: 1950739200000 },
    2032: { start: 1964048400000, end: 1982707200000 },
  };
  var date = new Date(UTCDateWithHour);
  var year = date.getUTCFullYear();
  var DSTStartEnd = DSTDATES[year];
  if (!DSTStartEnd) {
    return false;
  } else {
    if (
      UTCDateWithHour >= DSTStartEnd.start &&
      UTCDateWithHour <= DSTStartEnd.end
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export const convertUTCDate = (clientDate, hour, minute, second) => {
  var utcDate = Date.UTC(
    clientDate.getUTCFullYear(),
    clientDate.getUTCMonth(),
    clientDate.getUTCDate()
  );
  var offsetBE = 60;
  if (isNewDST(utcDate, hour)) {
    offsetBE = offsetBE * 2;
  }
  var offset = offsetBE * -1;
  utcDate = utcDate + offset * 60 * 1000;
  hour = hour != null ? hour : 0;
  minute = minute != null ? minute : 0;
  second = second != null ? second : 0;
  utcDate = utcDate + hour * 60 * 60 * 1000;
  utcDate = utcDate + minute * 60 * 1000;
  utcDate = utcDate + second * 1000;
  return utcDate;
};
