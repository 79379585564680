const getDistance = (coordinates1, coordinates2) => {
  var rad = function (x) {
    return (x * Math.PI) / 180;
  };
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(coordinates2[1] - coordinates1[1]);
  var dLong = rad(coordinates2[0] - coordinates1[0]);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(coordinates1[1])) *
      Math.cos(rad(coordinates2[1])) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in meter
};

export const othersNearBy = (coordinates, listOfCoordinates) => {
  let numberOfNearby = 0;
  if (listOfCoordinates?.length) {
    listOfCoordinates.forEach((coordinate) => {
      if (getDistance(coordinates, coordinate) <= 25) {
        numberOfNearby++;
      }
    });
  }
  return numberOfNearby;
};

export const createAddressForNavigation = (address, google) => {
  let fullAddress = '';
  if (address.geometry && address.geometry.coordinates) {
    fullAddress +=
      address.geometry.coordinates[1] + ',' + address.geometry.coordinates[0];
  } else {
    fullAddress +=
      address.street && address.street.length ? address.street : '';
    fullAddress +=
      address.city && address.city.fullCity
        ? fullAddress.length
          ? ` ${address.city.fullCity}`
          : address.city.fullCity
        : '';
  }

  /*fullAddress +=
    address.country && address.country.country
      ? fullAddress.length
        ? ` ${address.country.country}`
        : address.country.country
      : '';*/
  fullAddress = google ? fullAddress.split(' ').join('+') : fullAddress;
  return encodeURI(fullAddress);
};
