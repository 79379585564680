import { useEffect, useState } from 'react';
import { oneofficeAppURI } from '../config';
import Spinner from '../components/Spinner';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import AvailablesList from '../components/AvailablesList';

const Availabilities = () => {
  const [planning, setPlanning] = useState([]);
  const [fetching, setFetching] = useState(false);

  const loadPlanning = async () => {
    setFetching(true);
    const res = await fetch(`${oneofficeAppURI}availables`);
    setFetching(false);
    const data = await res.json();
    if (data?.planning) {
      setPlanning(data.planning);
    }
  };

  useEffect(() => {
    loadPlanning();
  }, []);

  return (
    <div className="p-4 overflow-scroll overflow-x-hidden h-[calc(100vh-60px)]">
      {fetching ? <Spinner /> : null}
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Loodswezen Beschikbaarheden</h1>
        <button
          onClick={() => loadPlanning()}
          className="bg-primary-400 text-xs p-2 rounded-md"
        >
          <ArrowPathIcon className="w-4 h-4 float-left mr-2" /> Lijst vernieuwen
        </button>
      </div>

      <AvailablesList availables={planning} />
    </div>
  );
};

export default Availabilities;
