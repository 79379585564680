import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { oneofficeAppURI } from '../config';
import Spinner from '../components/Spinner';
import {
  ArrowPathIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/react/24/outline';
import { ClockIcon, MapPinIcon, PlayIcon } from '@heroicons/react/24/solid';

const AvailabilitiesPerDriver = () => {
  const [planning, setPlanning] = useState([]);
  const [fetching, setFetching] = useState(false);

  const createAvailabilitiesPerDay = (planning) => {
    let dailyPlanning = [];
    let dailyPlan = null;
    planning.forEach((planItem) => {
      if (!dailyPlan || dailyPlan.execDateUTC !== planItem.execDateUTC) {
        if (dailyPlan) {
          dailyPlanning.push(dailyPlan);
        }
        dailyPlan = {
          execDateUTC: JSON.parse(JSON.stringify(planItem.execDateUTC)),
          driverStandBy: [],
        };
      }
      var found = false;
      for (let d = 0; d < dailyPlan.driverStandBy?.length; d++) {
        if (dailyPlan.driverStandBy[d].driver?._id === planItem.driver._id) {
          found = true;
          dailyPlan.driverStandBy[d].standByTimes.push({
            time: planItem.route?.places[0].pickupTime,
            vehicle: planItem.vehicle,
            route:
              planItem.route?.places[0].address?.name || 'Adres niet gekend',
          });
        }
      }
      if (!found) {
        dailyPlan.driverStandBy.push({
          driver: planItem.driver,
          standByTimes: [
            {
              time: planItem.route?.places[0].pickupTime,
              vehicle: planItem.vehicle,
              route:
                planItem.route?.places[0].address?.name || 'Adres niet gekend',
            },
          ],
          lisVehicleReserved: planItem.lisVehicleReserved || false,
        });
      }
    });
    if (dailyPlan) {
      dailyPlanning.push(dailyPlan);
    }
    return dailyPlanning;
  };

  const loadPlanning = async () => {
    setFetching(true);
    const res = await fetch(`${oneofficeAppURI}availables`);
    setFetching(false);
    const data = await res.json();
    if (data?.planning) {
      setPlanning(createAvailabilitiesPerDay(data.planning));
    }
  };

  useEffect(() => {
    loadPlanning();
  }, []);

  return (
    <div className="p-4 overflow-scroll overflow-x-hidden h-[calc(100vh-60px)]">
      {fetching ? <Spinner /> : null}
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">
          Loodswezen Beschikbaarheden Per Dag
        </h1>
        <button
          onClick={() => loadPlanning()}
          className="bg-primary-400 text-xs p-2 rounded-md"
        >
          <ArrowPathIcon className="w-4 h-4 float-left mr-2" /> Lijst vernieuwen
        </button>
      </div>

      <div
        className="flex flex-row mt-4"
        style={{
          flexFlow: 'row wrap',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
        }}
      >
        {planning?.map((planItem, p) => (
          <div
            key={p}
            className="p-2 border-2 border-gray-400 bg-gray-100 flex flex-col mr-2 mb-4"
          >
            <h2 className="font-bold text-sm mb-4">
              {format(planItem.execDateUTC, 'EEEE dd/MM')}
            </h2>
            {planItem.driverStandBy?.map((driverSb, sb) => (
              <div key={sb} className="text-xs mb-4">
                <p className="font-bold">{driverSb.driver.alias}</p>
                <p className="mb-2">{driverSb.driver.gsmWork}</p>
                <p className="flex items-center">
                  <PlayIcon
                    style={{ width: 10, height: 10, color: 'green' }}
                    className="mr-1"
                  />
                  {driverSb.driver.lisStartPlace ||
                    driverSb.driver.city?.fullCity ||
                    'Woonplaats niet gedeeld'}
                </p>
                <p className="font-bold">{driverSb.vehicle?.licensePlate}</p>
                {driverSb.standByTimes.map((sbTime, st) => (
                  <div key={st} className="mb-2">
                    <span className="text-green-600 flex items-center">
                      <ClockIcon
                        style={{ width: 12, height: 12 }}
                        className="mr-1"
                      />
                      {sbTime.time}
                    </span>
                    {sbTime.vehicle?.licensePlate ? (
                      <span className="flex items-center">
                        {/*<TruckIcon
                          style={{ width: 12, height: 12 }}
                          className="mr-1"
                    />*/}
                        {sbTime.vehicle.licensePlate !== 'INGEVEN' ? (
                          <img
                            src={`/img/${
                              parseInt(
                                sbTime.vehicle.model?.category?.seats || 0
                              ) <= 3
                                ? 'car'
                                : 'bus'
                            }.png`}
                            width="20"
                            height="8"
                            alt={sbTime.vehicle.licensePlate}
                            style={{ marginRight: 4 }}
                          />
                        ) : null}
                        {sbTime.vehicle?.licensePlate}
                        {sbTime.vehicle.licensePlate !== 'INGEVEN'
                          ? `(${sbTime.vehicle.model?.category?.seats || '?'}p)`
                          : ''}
                        {driverSb.lisVehicleReserved ? (
                          <span>
                            <ArrowsRightLeftIcon className="w-4 h-4 text-red-500 ml-1" />
                          </span>
                        ) : null}
                      </span>
                    ) : null}
                    <span className="flex items-center">
                      <MapPinIcon
                        style={{ width: 12, height: 12 }}
                        className="mr-1"
                      />{' '}
                      {sbTime.route}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvailabilitiesPerDriver;
