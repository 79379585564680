import { useAuth } from '../hooks/useAuth';

export const useHeaders = (secure, customToken) => {
  const { jwt } = useAuth();
  let headers = {
    'Content-Type': 'application/json',
  };
  let token = customToken || jwt;
  if (secure && token) {
    headers.Authorization = 'Bearer ' + token;
  }
  return headers;
};
