import { useSideBar } from '../hooks/useSideBar';

const RightSideBar = () => {
  const {
    rightSideBarOpen,
    rightSideBarContent,
    rightSideBarExpanded,
    hideRightSideBar,
  } = useSideBar();

  return (
    <div className={`absolute top-0 left-0`}>
      <div
        className={`fixed top-0 left-0 w-screen h-screen bg-black opacity-60 z-[9997] hover:cursor-pointer ${
          rightSideBarOpen ? 'flex' : 'hidden'
        }`}
        onClick={() => hideRightSideBar()}
      />
      <div
        className={`fixed overflow-x-hidden overflow-y-scroll p-4 bg-slate-300 flex flex-col h-screen ease-in-out z-[9998] ${
          rightSideBarExpanded ? 'duration-500' : 'duration-300'
        } ${rightSideBarOpen ? 'right-0' : '-right-[100vw]'} ${
          rightSideBarExpanded
            ? `w-full max-w-[720px]`
            : 'w-[100vw] max-w-[360px]'
        }`}
      >
        {rightSideBarContent || null}
      </div>
    </div>
  );
};

export default RightSideBar;
