/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { oneofficeAppURI } from '../config';
import { useAuth } from '../hooks/useAuth';
import { getUserForToken } from '../token';
import Spinner from '../components/Spinner';
import { format } from 'date-fns';
import { ChatBubbleBottomCenterIcon } from '@heroicons/react/24/outline';
import { uploadFile } from '../api/S3Upload/S3Upload';
import AWS from 'aws-sdk';

const initTicket = {
  locations: ['Antwerpen (A)'],
  status: 'Open',
};

const Locations = [
  'Antwerpen (A)',
  'Gent (G)',
  'Vlissingen (V)',
  'Zeebrugge (ZZ)',
];

const maxSizeKB = 2048;

const TicketForm = ({ id, close, forceReload }) => {
  const [newTicket, setNewTicket] = useState({ ...initTicket });
  const [newReaction, setNewReaction] = useState();
  const [newFile, setNewFile] = useState();
  const [fetching, setFetching] = useState();
  const { jwt } = useAuth();
  const user = jwt ? getUserForToken(jwt) : null;

  const loadTicketDetail = async () => {
    setFetching(true);
    const res = await fetch(`${oneofficeAppURI}tickets/${id}`);
    setFetching(false);
    const data = await res.json();
    if (data?.ticket) {
      setNewTicket(data.ticket);
    }
  };

  const updateTicket = (prop, val) => {
    setNewTicket({
      ...newTicket,
      [prop]: val,
    });
  };

  const saveNewTicket = async (e, forceTicket, stay) => {
    e.preventDefault();
    if (!newTicket.locations?.length) {
      return toast.error('Geen locatie gekozen');
    }
    setFetching(true);
    const res = await fetch(`${oneofficeAppURI}tickets/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ticket: forceTicket ? { ...forceTicket } : { ...newTicket },
        user,
      }),
    });
    setFetching(false);
    const data = await res.json();
    if (data?.success) {
      toast.success(
        newTicket._id ? 'Ticket werd bijgewerkt' : 'Ticket werd aangemaakt'
      );
      if (!stay) {
        setNewTicket({ ...initTicket });
        close(true);
      } else {
        if (forceReload) {
          forceReload();
        }
      }
    } else {
      toast.error('Probleem bij opslaan ticket.');
    }
  };

  const addReaction = (e) => {
    e.preventDefault();
    let updateTicket = { ...newTicket };
    updateTicket.reactions = updateTicket.reactions || [];
    updateTicket.reactions.unshift({
      text: newReaction,
      by: user.fullName,
      on: Date.now(),
    });
    setNewReaction(undefined);
    setNewTicket(updateTicket);
    saveNewTicket(e, updateTicket, true);
  };

  const uploadNewFile = async (e) => {
    e.preventDefault();
    if (!newFile) return;
    const prefix = 'lisupload';
    const config = {
      bucketName: 'gorsele-uploads',
      region: 'eu-central-1',
      accessKeyId: 'AKIA4ZZF2SCZLATATJMJ',
      secretAccessKey: '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',
      dirName: prefix + '_' + Date.now(),
    };
    let extensions = newFile.name.split('.');
    let newName =
      extensions.slice(0, extensions.length - 1).join('') + Date.now();
    let randomfile = Math.floor(Math.random() * (999999 - 111111 + 1)) + 111111;
    newName = `${randomfile}_${Date.now()}.${
      extensions[extensions.length - 1]
    }`;
    config.fileName = newName;
    config.key = newName;
    config.Key = newName;
    setFetching(true);

    AWS.config.update({
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
      region: 'eu-central-1',
      s3_signature_version: 'v4',
    });

    let AWSBucket = new AWS.S3({
      params: { Bucket: 'gorsele-uploads' },
    });

    var params = {
      Key: newName,
      ContentType: newFile?.type,
      Body: newFile,
    };

    AWSBucket.upload(params, (err, uploadRes) => {
      if (err) {
        toast.error('Probleem bij uploaden.');
      } else {
        if (uploadRes.Location || uploadRes.location) {
          const location = encodeURI(uploadRes.Location || uploadRes.location);
          let updateTicket = { ...newTicket };
          updateTicket.attachments = updateTicket.attachments || [];
          updateTicket.attachments.unshift({
            location,
            by: user.fullName,
            on: Date.now(),
          });
          setNewTicket(updateTicket);
          saveNewTicket(e, updateTicket, true);
          setNewFile(undefined);
          window.document.getElementById('fileupload').value = '';
        } else {
          toast.error('Probleem bij uploaden.');
        }
      }
    });
  };

  const resetNewTicket = (e) => {
    e.preventDefault();
    setNewTicket({ ...initTicket });
    close();
  };

  const fileChange = (e) => {
    e.preventDefault();
    let files = e.target.files;
    for (let f = 0; f < files.length; f++) {
      const size = Math.ceil(files[f].size / 1024);
      if (size > maxSizeKB) {
        window.document.getElementById('fileupload').value = '';
        return toast.error('Bestand is te groot');
      }
    }
    setNewFile(files[0]);
  };

  const removeTicket = async (e, id) => {
    e.preventDefault();
    setFetching(true);
    const res = await fetch(`${oneofficeAppURI}tickets/remove`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        user,
      }),
    });
    setFetching(false);
    const data = await res.json();
    if (data?.success) {
      toast.success('Ticket verwijderd');
      setNewTicket({ ...initTicket });
      close(true);
    } else {
      toast.error('Probleem bij verwijderen ticket.');
    }
  };

  useEffect(() => {
    if (id) {
      loadTicketDetail();
    }
  }, [id]);

  return (
    <form className="appform flex flex-col w-full" onSubmit={saveNewTicket}>
      {fetching ? <Spinner /> : null}
      <div className="flex flex-row w-full">
        <div
          className={`flex flex-col w-[100%] ${
            newTicket._id ? 'lg:w-[50%]' : 'lg:w-[100%]'
          }`}
        >
          {newTicket.docNR ? <label>TICKET {newTicket.docNR}</label> : null}
          <label>Titel</label>
          <input
            className="appinput"
            type="text"
            value={newTicket.title || ''}
            onChange={(e) => updateTicket('title', e.currentTarget.value)}
            required
          />
          <label>LIS ID</label>
          <input
            className="appinput"
            type="text"
            value={newTicket.LisOpdrachtId || ''}
            onChange={(e) =>
              updateTicket('LisOpdrachtId', e.currentTarget.value)
            }
          />
          <label>Locatie(s)</label>
          {Locations.map((location, l) => (
            <label key={l} className="appcheckbox">
              <input
                type="checkbox"
                name={location}
                checked={newTicket.locations?.indexOf(location) !== -1}
                onChange={() => {
                  if (newTicket.locations?.indexOf(location) !== -1) {
                    let newTicketLocations = [...newTicket.locations];
                    newTicketLocations.splice(
                      newTicket.locations?.indexOf(location),
                      1
                    );
                    updateTicket('locations', newTicketLocations);
                  } else {
                    updateTicket(
                      'locations',
                      newTicket.locations.concat([location])
                    );
                  }
                }}
              />
              {location}
            </label>
          ))}
          <label>Categorie</label>
          <select
            className="appselect"
            value={newTicket.category || ''}
            required
            onChange={(e) => updateTicket('category', e.currentTarget.value)}
          >
            <option value="">Kies een categorie</option>
            <option>Wagenpark</option>
            <option>Chauffeur</option>
            <option>Dispatcher</option>
            <option>Loods</option>
            <option>Veiligheid</option>
            <option>Planning</option>
          </select>
          <label>Prioriteit</label>
          <select
            className="appselect"
            value={newTicket.priority || ''}
            required
            onChange={(e) => updateTicket('priority', e.currentTarget.value)}
          >
            <option value="">Kies een prioriteit</option>
            <option>Normaal</option>
            <option>Hoog</option>
            <option>Dringend</option>
          </select>
          <label>Status</label>
          <select
            className="appselect"
            value={newTicket.status || ''}
            required
            onChange={(e) => updateTicket('status', e.currentTarget.value)}
          >
            <option>Open</option>
            <option>In behandeling</option>
            <option>Afgesloten</option>
          </select>
          <label>Afhandeling door</label>
          <select
            className="appselect"
            value={newTicket.handler || ''}
            required
            onChange={(e) => updateTicket('handler', e.currentTarget.value)}
          >
            <option value="">Selecteer afhandelaar</option>
            <option>Lodico</option>
            <option>Gorsele Dispatch</option>
          </select>
          <label>Omschrijving</label>
          <textarea
            value={newTicket.description || ''}
            onChange={(e) => updateTicket('description', e.currentTarget.value)}
            rows={8}
            required
            className="p-2"
          ></textarea>
        </div>
        {newTicket._id ? (
          <div className="flex flex-col w-[100%] lg:w-[50%] pl-4">
            <label>Reacties</label>
            {newTicket.reactions?.length
              ? newTicket?.reactions.map((reaction, r) => (
                  <div key={r} className="rounded-lg bg-gray-200 p-2 mb-2">
                    <span className="flex items-center">
                      <ChatBubbleBottomCenterIcon className="w-4 h-4 mr-2" />{' '}
                      <strong>
                        {reaction.by} op {format(reaction.on, 'dd/MM HH:mm')}
                      </strong>
                    </span>
                    {reaction.text}
                  </div>
                ))
              : null}
            <div className="mb-2">
              <textarea
                className="w-full p-2"
                value={newReaction || ''}
                onChange={(e) => setNewReaction(e.currentTarget.value)}
                id="reaction"
                placeholder="Schrijf hier uw reactie"
                rows={3}
              ></textarea>
              {newReaction?.length >= 3 ? (
                <button
                  className="mt-1 rounded-sm bg-blue-400 text-white p-2 pl-4 pr-4 w-full"
                  onClick={(e) => addReaction(e)}
                >
                  Voeg reactie toe
                </button>
              ) : null}
            </div>
            <label>
              Bijlagen
              {newTicket.attachments?.length ? (
                <span className="ml-1">({newTicket.attachments.length})</span>
              ) : null}
            </label>
            {newTicket.attachments?.length ? (
              <ul className="mb-2">
                {newTicket.attachments.map((attachment, a) => (
                  <li key={a}>
                    <a
                      className="font-bold text-blue-500"
                      href={attachment.location}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Bijlage {a + 1} door {attachment.by}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Geen bijlagen</p>
            )}
            <input
              id="fileupload"
              name="fileupload"
              type="file"
              className="mt-1 rounded-sm bg-gray-400 text-white p-2 pl-4 pr-4 w-full"
              accept=".doc,.docx,.pdf,.jpg,.png,.jpeg"
              onChange={(e) => fileChange(e)}
            />
            {newFile ? (
              <button
                className="mt-1 rounded-sm bg-blue-400 text-white p-2 pl-4 pr-4 w-full"
                onClick={(e) => uploadNewFile(e)}
              >
                Voeg bestand toe aan ticket
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
      <button
        type="submit"
        className="mt-1 rounded-sm bg-green-400 text-white p-2 pl-4 pr-4 w-full"
      >
        {newTicket._id ? 'Ticket bijwerken' : 'Maak ticket aan'}
      </button>
      <button
        className="mt-1 rounded-sm bg-gray-400 text-white p-2 pl-4 pr-4 w-full"
        onClick={(e) => resetNewTicket(e)}
      >
        Annuleren
      </button>
      {newTicket._id ? (
        <button
          className="mt-1 text-red-500 font-bold p-2 w-full hover:underline"
          onClick={(e) => removeTicket(e, newTicket._id)}
        >
          Verwijder ticket
        </button>
      ) : null}
    </form>
  );
};

export default TicketForm;
