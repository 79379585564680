import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import AppHeader from './AppHeader';
import { useSideBar } from '../hooks/useSideBar';
import RightSideBar from './RightSideBar';

const PageLayout = () => {
  const { sideBarOpen, toggleSidebar } = useSideBar();

  return (
    <div className="app">
      <RightSideBar />
      <AppHeader />
      <div className="page-container" aria-hidden="false">
        <Sidebar />
        <div className={`main ${sideBarOpen ? 'ewsidebar' : 'enosidebar'}`}>
          <div className="content">
            <div className="h-full w-full block">{<Outlet />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
