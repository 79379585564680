import decode from 'jwt-decode';

export const getUserForToken = (token) => {
  if (isTokenExpired(token)) {
    return null;
  }
  let decoded = token && decode(token);
  if (decoded && decoded.user) {
    let u = decoded.user;
    return u;
  }
  return null;
};

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }
  const date = new Date(0);
  date.setUTCSeconds(token.exp);
  return date;
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}

export const isValidToken = (token) => {
  return !!token && !isTokenExpired(token);
};
