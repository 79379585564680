export const onroadAppURI =
  process.env.NODE_ENV === 'development'
    ? 'https://gorsele-onroad-back.herokuapp.com/api/loodswezen/'
    : 'https://gorsele-onroad-back.herokuapp.com/api/loodswezen/';
export const oneofficeAppURI =
  process.env.NODE_ENV === 'development'
    ? 'https://gorsele-oneoffice-back.herokuapp.com/api/anonymous/lis/'
    : 'https://gorsele-oneoffice-back.herokuapp.com/api/anonymous/lis/';
export const gmapKey = 'AIzaSyC3srYRgae0wLC3p46FbjdvP8W9cQqLhkM'; //process.env.GMAPKEY;
export const webfleetURL =
  'https://csv.webfleet.com/extern?account=autocarsgorsele&username=webfleet&password=webfleet1!&apikey=4247b6c5-e95c-4184-9120-0de677ba5075&lang=nl&outputformat=json&action=showObjectReportExtern&filterstring=';
export const marineTrafficURL = 'https://services.marinetraffic.com/api/';
export const marineTrafficAPIKey = '516021a0ed67d097b372242ee70065838921e481';
export const versionNumber = '2';
