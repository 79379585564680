import {
  ArrowsRightLeftIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import authFetchWrapper from '../api/authFetchWrapper';
import { onroadAppURI } from '../config';
import toast from 'react-hot-toast';
import { useHeaders } from '../hooks/useHeaders';

const remarkList = [
  'Te laat met shift begonnen',
  'Niet op tijd voor opdracht',
  'Rit niet correct aanvaard',
  'Starttijd niet correct geregistreerd',
  'Aankomsttijd niet correct geregistreerd',
  'Instap niet correct geregistreerd',
  'Uitstap niet correct geregistreerd',
  'Foutieve nummerplaat ingegeven',
  'SMS niet correct gelezen',
  'Chauffeur werkt niet voldoende mee',
  'Track niet in orde',
];

const AvailablesList = ({ availables }) => {
  const headers = useHeaders(true);

  const addRemark = (shift) => {
    Swal.fire({
      title: 'Opmerking maken',
      text: 'Kies een opmerking uit de lijst.',
      input: 'select',
      inputOptions: remarkList,
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Opmerking toevoegen',
      cancelButtonText: 'Annuleer',
    }).then(async (result) => {
      if (result.isConfirmed && result?.value !== undefined) {
        const reasonIndex = parseInt(result.value);
        const reason = remarkList[reasonIndex];
        const res = await authFetchWrapper(
          `${onroadAppURI}protected/addremark`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
              remark: {
                reason,
                execDateUTC: shift.execDateUTC,
                pickupTime: shift.route?.places[0].pickupTime,
                driver: shift.driver,
                vehicle: shift.vehicle,
              },
            }),
          }
        );
        const data = await res.json();
        if (data?.success) {
          return toast.success('Opmerking toegevoegd');
        } else {
          return toast.error('Probleem bij aanmaken opmerking');
        }
      }
    });
  };

  return (
    <table className="text-xs border mt-4 apptable" width="100%">
      <thead>
        <tr className="font-bold bg-gray-300">
          <td>Datum</td>
          <td>Beschikbaar van - tot</td>
          <td>Nummerplaat</td>
          <td>Chauffeur</td>
          <td>Startplaats</td>
          <td>GSM</td>
          <td>Wagen</td>
          <td>Stand-by locatie</td>
          <td>&nbsp;</td>
        </tr>
      </thead>
      <tbody>
        {availables?.map((planItem, p) => (
          <tr key={p}>
            <td>{format(planItem.execDateUTC, 'EEEE dd/MM')}</td>
            <td>{planItem.route?.places[0].pickupTime}</td>
            <td>
              <span style={{ display: 'flex' }}>
                {planItem.vehicle.licensePlate !== 'INGEVEN' ? (
                  <img
                    src={`/img/${
                      parseInt(planItem.vehicle.model?.category?.seats || 0) <=
                      3
                        ? 'car'
                        : 'bus'
                    }.png`}
                    width="20px"
                    height="8px"
                    alt={planItem.vehicle.licensePlate}
                    style={{ marginRight: 4, width: 20, height: 8 }}
                  />
                ) : null}
                {planItem.vehicle.licensePlate}{' '}
                {planItem.vehicle.licensePlate !== 'INGEVEN'
                  ? `(${planItem.vehicle.model?.category?.seats || '?'}p)`
                  : ''}
                {planItem.lisVehicleReserved ? (
                  <span>
                    <ArrowsRightLeftIcon className="w-4 h-4 text-red-500 ml-1" />
                  </span>
                ) : null}
              </span>
            </td>
            <td>{planItem.driver.alias}</td>
            <td>
              {planItem.driver.lisStartPlace || planItem.driver.city?.fullCity}
            </td>
            <td>{planItem.driver.gsmWork}</td>
            <td>
              {planItem.vehicle.model.fullModel}{' '}
              {planItem.vehicle.model.category.name}
            </td>
            <td>
              {planItem.route?.places[0].address?.fullAddress ||
                'Adres niet gekend'}
            </td>
            <td>
              {/*
              <span>
                <ExclamationCircleIcon
                  className="w-4 h-4 text-red-500 ml-2 mr-2 hover:cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    addRemark(planItem);
                  }}
                />
                </span>*/}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AvailablesList;
