import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';
import { format, intervalToDuration } from 'date-fns';
import { useSideBar } from '../hooks/useSideBar';
import TicketForm from '../views/TicketForm';

const TicketList = ({ tickets, onReload }) => {
  const { showRightSideBar, hideRightSideBar } = useSideBar();

  const openTicketDetail = (ticket) => {
    showRightSideBar(
      <TicketForm
        id={ticket._id}
        close={(reload) => {
          hideRightSideBar();
          if (reload) {
            onReload();
          }
        }}
        forceReload={() => {
          onReload();
        }}
      />,
      true
    );
  };

  return (
    <div
      className="flex flex-row mt-4"
      style={{
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      {tickets?.length
        ? tickets.map((ticket, t) => (
            <div
              key={t}
              className={`rounded-lg bg-gray-100 p-2 lg:w-[31%] md:w-[48%] md:mr-[1%] w-full relative text-xs hover:cursor-pointer mb-4 ${
                ticket.status === 'Afgesloten' ? 'bg-green-200' : ''
              }`}
              onClick={() => openTicketDetail(ticket)}
            >
              <div className="absolute top-2 right-1 flex items-center">
                {ticket.status !== 'Afgesloten' &&
                ticket.priority !== 'Normaal' ? (
                  <span className="mr-2">
                    <ExclamationTriangleIcon
                      className={`w-6 h-6 ${
                        ticket.priority === 'Hoog'
                          ? 'text-orange-400'
                          : 'text-red-800'
                      }`}
                    />
                  </span>
                ) : null}
                {ticket.status !== 'Afgesloten' ? (
                  <span
                    className={`p-1 pl-2 pr-2 rounded-lg text-xs text-white ${
                      ticket.status === 'Open' ? 'bg-red-400' : 'bg-blue-400'
                    }`}
                  >
                    {ticket.status}
                  </span>
                ) : (
                  <CheckCircleIcon className="text-green-400 w-4 h-4" />
                )}
              </div>
              <p className="text-xs font-bold">TICKET {ticket.docNR}</p>
              <h3 className="font-bold text-blue-600 text-lg mr-[40px]">
                {ticket.title}
              </h3>
              {ticket.status !== 'Afgesloten' ? (
                <>
                  <p className="mt-2 mb-2">{ticket.description}</p>
                  {ticket.reactions?.length ? (
                    <div className="rounded-lg p-2 bg-gray-200 mb-2">
                      <strong>
                        Laatste reactie door {ticket.reactions[0].by} op{' '}
                        {format(ticket.reactions[0].on, 'dd/MM HH:mm')}
                      </strong>
                      <br />
                      {ticket.reactions[0].text}
                    </div>
                  ) : null}
                  {ticket.attachments?.length ? (
                    <p>
                      <strong>Bijlagen:</strong> {ticket.attachments.length}
                    </p>
                  ) : null}
                  {ticket.LisOpdrachtId ? (
                    <p>
                      <strong>LIS ID:</strong> {ticket.LisOpdrachtId}
                    </p>
                  ) : null}
                  <p>
                    <strong>Afhandeling door:</strong> {ticket.handler}
                  </p>
                  <p>
                    <strong>Laatste update:</strong> {ticket.updatedBy.fullName}{' '}
                    om {format(ticket.updated, 'dd/MM HH:mm')}
                  </p>
                  <p>
                    <strong>Dagen in systeem:</strong>{' '}
                    {intervalToDuration({
                      start: ticket.created || ticket.updated,
                      end: Date.now(),
                    }).days + 1}
                  </p>
                </>
              ) : null}
            </div>
          ))
        : null}
    </div>
  );
};

export default TicketList;
