import { useEffect, useState } from 'react';
import { oneofficeAppURI } from '../config';
import Spinner from '../components/Spinner';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import LogList from '../components/LogList';

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [fetching, setFetching] = useState(false);

  const loadLogs = async (onlyErrors) => {
    setFetching(true);
    const res = await fetch(
      `${oneofficeAppURI}logs${onlyErrors ? '/errors' : ''}`
    );
    setFetching(false);
    const data = await res.json();
    if (data?.logs) {
      setLogs(data.logs);
    }
  };

  useEffect(() => {
    loadLogs();
  }, []);

  return (
    <div className="p-4 overflow-scroll overflow-x-hidden h-[calc(100vh-60px)]">
      {fetching ? <Spinner /> : null}
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Loodswezen Logs</h1>
        <div className="flex flex-row">
          <button
            onClick={() => loadLogs()}
            className="bg-primary-400 text-xs p-2 rounded-md"
          >
            <ArrowPathIcon className="w-4 h-4 float-left mr-2" /> Lijst
            vernieuwen
          </button>
          <button
            onClick={() => loadLogs(true)}
            className="bg-red-400 text-xs p-2 rounded-md ml-2"
          >
            <ArrowPathIcon className="w-4 h-4 float-left mr-2" /> Enkel Fouten
          </button>
        </div>
      </div>

      <LogList logs={logs} />
    </div>
  );
};

export default Logs;
