import { useState } from 'react';
import Spinner from '../components/Spinner';
import { useAuth } from '../hooks/useAuth';
import { onroadAppURI } from '../config';
import GorseleLogo from '../assets/img/gorsele_logo_web.png';

const errorMessages = {
  authFailed: 'Je kan niet aanmelden met deze gegevens.',
};

const SignIn = () => {
  const [error, setError] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [signingIn, setSigningIn] = useState(false);

  const { login } = useAuth();

  const signin = async (e) => {
    e.preventDefault();
    setSigningIn(true);
    const res = await fetch(`${onroadAppURI}anonymous/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });
    setSigningIn(false);
    const data = await res.json();
    if (!data?.token) {
      return setError(errorMessages[data.error] || 'Fout bij aanmelden');
    }
    login(data.token);
  };

  return (
    <div className="ano-bg">
      <div className="absolute bottom-2 right-2">
        <img src={GorseleLogo} width="120" height="auto" alt="logo" />
      </div>
      <div className="text-center mb-4">
        <span className="font-bold text-lg text-black">
          Loodswezen Dispatch Center
        </span>
      </div>
      <div className="container max-w-md mx-auto flex flex-col bg-white shadow-lg xl:p-12 lg:p-6 p-4 rounded-lg">
        <h1 className="font-bold mb-2">Aanmelden</h1>
        {error && <p className="text-red-400">{error}</p>}
        <form className="flex flex-col" onSubmit={signin}>
          <label htmlFor="username">Gebruikersnaam</label>
          <input
            id="username"
            name="username"
            alt="username"
            required
            value={username || ''}
            onChange={(e) => setUsername(e.currentTarget.value)}
            className="rounded border mb-2 p-2"
          />
          <label htmlFor="password">Wachtwoord</label>
          <input
            type="password"
            id="password"
            name="password"
            alt="password"
            required
            value={password || ''}
            onChange={(e) => setPassword(e.currentTarget.value)}
            className="rounded border mb-2 p-2"
          />
          {signingIn ? (
            <Spinner />
          ) : (
            <>
              <input
                type="submit"
                className="styled-btn primary"
                value="Aanmelden"
              />
            </>
          )}
        </form>
      </div>
      <div className="flex justify-center mt-2 text-xs">
        <span>
          Gorselé BV {new Date().getFullYear()} - Development by{' '}
          <a
            href="https://www.linkedin.com/in/kenneeckx"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Ken Neeckx
          </a>
        </span>
      </div>
    </div>
  );
};

export default SignIn;
