import { useEffect, useState } from 'react';
import { oneofficeAppURI } from '../config';
import { ArrowPathIcon, EyeIcon, PlusIcon } from '@heroicons/react/24/solid';
import Spinner from '../components/Spinner';
import { useSideBar } from '../hooks/useSideBar';
import MessageForm from './MessageForm';
import { format } from 'date-fns';

const Messages = () => {
  const [messages, setMessages] = useState();
  const [fetching, setFetching] = useState();
  const { showRightSideBar, hideRightSideBar } = useSideBar();

  const loadMessages = async () => {
    setFetching(true);
    //setShowNew(false);
    const res = await fetch(`${oneofficeAppURI}messages`);
    setFetching(false);
    const data = await res.json();
    if (data?.messages) {
      setMessages(data.messages);
    }
  };

  const showNewMessage = () => {
    showRightSideBar(
      <MessageForm
        close={(reload) => {
          hideRightSideBar();
          if (reload) {
            loadMessages();
          }
        }}
      />
    );
  };

  const toggleRecipients = (messageIndex) => {
    let newMessages = [...messages];
    newMessages[messageIndex].showRecipients =
      !newMessages[messageIndex].showRecipients;
    setMessages(newMessages);
  };

  useEffect(() => {
    loadMessages();
  }, []);

  return (
    <div className="p-4 overflow-scroll overflow-x-hidden h-[calc(100vh-60px)]">
      {fetching ? <Spinner /> : null}
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Berichten</h1>
        <div>
          <button
            onClick={() => showNewMessage()}
            className="bg-green-400 text-xs p-2 rounded-md mr-2"
          >
            <PlusIcon className="w-4 h-4 float-left mr-2" /> Nieuw bericht
            sturen
          </button>
          <button
            onClick={() => loadMessages()}
            className="bg-primary-400 text-xs p-2 rounded-md"
          >
            <ArrowPathIcon className="w-4 h-4 float-left mr-2" /> Lijst
            vernieuwen
          </button>
        </div>
      </div>

      <div
        className="flex flex-row mt-4"
        style={{
          flexFlow: 'row wrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        {messages?.length
          ? messages.map((message, m) => (
              <div
                key={m}
                className={`rounded-lg bg-gray-100 p-2 lg:w-[31%] md:w-[48%] md:mr-[1%] w-full text-xs mb-4`}
              >
                <p>
                  <strong>
                    Gestuurd door {message.by} op{' '}
                    {format(message.on, 'dd/MM HH:mm')}
                  </strong>
                </p>
                <p className="mt-2 mb-2">{message.text}</p>
                <p className="flex flex-row">
                  Aantal ontvangers: {message.recipients?.length}{' '}
                  <EyeIcon
                    className={`w-4 h-4  ml-2 hover:cursor-pointer ${
                      message.showRecipients
                        ? 'text-green-400'
                        : 'text-black-400'
                    }`}
                    onClick={() => toggleRecipients(m)}
                  />
                </p>
                {message.showRecipients ? (
                  <div className="mt-2 p-2 rounded-lg bg-green-200 w-full">
                    <table width="100%">
                      <tbody>
                        {message.recipients.map((recipient, r) => (
                          <tr key={r}>
                            <td>{recipient.alias}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default Messages;
