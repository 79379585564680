const Catch401 = (response) => {
  if (response?.status === 401) {
    return (window.location.href = `/signout`);
  } else {
    return;
  }
};

const authFetchWrapper = (url, params) => {
  return new Promise(async (resolve) => {
    const res = await fetch(url, params);
    Catch401(res);
    return resolve(res);
  });
};

export default authFetchWrapper;
