import { format } from 'date-fns';

const LogList = ({ logs }) => {
  return (
    <table className="text-xs border mt-4 apptable " width="100%">
      <thead>
        <tr className="font-bold bg-gray-300">
          <td width="100">Tijdstip</td>
          <td>Text</td>
        </tr>
      </thead>
      <tbody>
        {logs?.map((log, l) => (
          <tr
            key={l}
            className={`${log.error ? 'text-red-500 font-bold' : ''}`}
          >
            <td>{format(log.created, 'dd/MM HH:mm')}</td>
            <td>{log.text}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LogList;
