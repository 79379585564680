import { Link, useLocation } from 'react-router-dom';
import { useSideBar } from '../hooks/useSideBar';
import {
  ChatBubbleOvalLeftIcon,
  ListBulletIcon,
  MapIcon,
  QueueListIcon,
  UserGroupIcon,
  ClipboardDocumentListIcon,
  DevicePhoneMobileIcon,
  BookOpenIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

const NavigationItems = [
  {
    url: '/',
    icon: MapIcon,
    label: 'Kaart',
  },
  {
    url: '/planning',
    icon: QueueListIcon,
    label: 'Planning',
  },
  {
    url: '/availability',
    icon: UserGroupIcon,
    label: 'Beschikbaarheid',
  },
  {
    url: '/availabilityday',
    icon: ClipboardDocumentListIcon,
    label: 'Beschikbaarheid / dag',
  },
  {
    url: '/tickets',
    icon: ChatBubbleOvalLeftIcon,
    label: 'Tickets',
  },
  {
    url: '/messages',
    icon: DevicePhoneMobileIcon,
    label: 'Bericht Sturen',
  },
  {
    url: '/logs',
    icon: ListBulletIcon,
    label: 'Logs',
  },
  /*{
    url: '/remarks',
    icon: ExclamationCircleIcon,
    label: 'Opmerkingen',
  },*/
  {
    url: '/docs/loodswezen_handleiding_v1.pdf',
    icon: BookOpenIcon,
    label: 'Handleiding',
    external: true,
  },
];

const Sidebar = () => {
  const { sideBarOpen, sideBarWidth } = useSideBar();
  const location = useLocation();
  const [activePath, setActivePath] = useState(location?.pathname || '/');

  useEffect(() => {
    if (location?.pathname && location?.pathname !== activePath) {
      setActivePath(location.pathname);
    }
  }, [location?.pathname, activePath]);

  return (
    <div
      className={`sidebar ${sideBarOpen ? 'open' : 'closed'}`}
      style={{ width: sideBarWidth }}
    >
      <div className="container">
        <div className="nav">
          <ul>
            {NavigationItems.map((item, i) => {
              const Icon = item.icon;
              return (
                <li key={i} className="mb-4 w-full">
                  <Link
                    to={item.external ? { pathname: item.url } : item.url}
                    target={item.external ? '_blank' : '_self'}
                    rel={item.external ? 'noreferrer' : ''}
                    className={`text-white flex items-center w-full transition-all overflow-hidden hover:text-primary-200 hover:translate-x-1 ${
                      activePath === item.url ||
                      (item.url !== '/' && activePath.indexOf(item.url) === 0)
                        ? '!text-primary-200 !font-bold'
                        : ''
                    }`}
                  >
                    <Icon className="icon" style={{ width: 26, height: 26 }} />
                    <span
                      className={`text-sm ${
                        sideBarOpen ? 'visible' : 'hidden'
                      }`}
                    >
                      {item.label}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex-1" />
      </div>
      {sideBarOpen ? (
        <div className="drag w-1 right-0 absolute top-0 h-full bg-slate-300 hover:cursor-col-resize" />
      ) : null}
    </div>
  );
};

export default Sidebar;
