/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useMemo, useState } from 'react';
import { oneofficeAppURI } from '../config';
const RecentItemContext = createContext();

export const RecentItemProvider = ({ children }) => {
  const [recentItems, setRecentItems] = useState();
  const [recentFetching, setRecentFetching] = useState(false);
  // call this function when you want the sidebar to open/close

  const refreshRecentItems = async () => {
    setRecentFetching(true);
    const res = await fetch(`${oneofficeAppURI}recentitems`);
    setRecentFetching(false);
    const data = await res.json();
    if (data?.items) {
      setRecentItems(data.items);
    }
  };

  const value = useMemo(
    () => ({
      recentItems,
      recentFetching,
      refreshRecentItems,
    }),
    [recentItems, recentFetching]
  );
  return (
    <RecentItemContext.Provider value={value}>
      {children}
    </RecentItemContext.Provider>
  );
};

export const useRecentItems = () => {
  return useContext(RecentItemContext);
};
