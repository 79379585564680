/* eslint-disable react-hooks/exhaustive-deps */
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { oneofficeAppURI } from '../config';
import LogList from './LogList';
import PlanningList from './PlanningList';
import { DotLoader } from 'react-spinners';
import AvailablesList from './AvailablesList';
import { useSearch } from '../hooks/useSearch';
import TicketList from './TicketList';

const SearchBar = ({ forceSearch }) => {
  const [searchText, setSearchText] = useState();
  const [searchResult, setSearchResult] = useState();
  const [searching, setSearching] = useState(false);
  const { searchVisible, searchIsVisible, searchIsHidden } = useSearch();

  const doSearch = async (e, forceSearchText) => {
    e?.preventDefault();
    if (!forceSearchText && (!searchText?.length || searchText.length < 3)) {
      return toast.error(
        'Gelieve minimum 3 karakters in te voeren om te kunnen zoeken.'
      );
    }
    setSearching(true);
    const url = `${oneofficeAppURI}search`;
    const res = await fetch(
      `${url}?${new URLSearchParams({
        searchText: forceSearchText || searchText,
      })}`
    );
    const data = await res.json();
    setSearching(false);
    if (data?.success) {
      setSearchResult(data.searchResult);
      searchIsVisible();
    } else {
      return toast.error('Probleem bij zoeken.');
    }
  };

  const closeResult = (e) => {
    e?.preventDefault();
    setSearchResult(undefined);
    setSearchText(undefined);
    searchIsHidden();
  };

  useEffect(() => {
    if (forceSearch?.length && forceSearch !== searchText) {
      setSearchText(forceSearch);
      doSearch(null, forceSearch);
    }
  }, [forceSearch]);

  return (
    <div className="flex flex-1 justify-center">
      <form onSubmit={doSearch} className="flex items-center">
        {searching ? (
          <div className="ml-2">
            <DotLoader loading={true} color="white" size={24} />
          </div>
        ) : (
          <MagnifyingGlassIcon className="w-[26px] h-[26px] text-slate-400 mr-2" />
        )}
        <input
          type="text"
          placeholder="Zoek op LIS ID, ticket, chauffeur, nummerplaat of passagier"
          className="w-[200px] md:w-[280px] lg:w-[420px] rounded-xl border-1 border-slate-900 bg-slate-600 text-xs lg:text-sm p-1 pl-4 text-slate-300"
          value={searchText || ''}
          onChange={(e) => {
            searchIsHidden();
            setSearchResult(undefined);
            setSearchText(e.currentTarget.value);
          }}
          autoComplete="off"
          spellCheck="false"
          autoCapitalize="off"
        />
        <button
          type="submit"
          className="rounded-xl bg-slate-900 text-slate-400 ml-2 text-xs lg:text-sm p-1 pl-4 pr-4 hover:bg-slate-400 hover:text-black"
        >
          Zoeken
        </button>
      </form>
      {searchVisible ? (
        <div className="fixed top-[60px] left-0 w-screen h-[calc(100vh-60px)] z-[9994]">
          <div className="p-4 overflow-scroll overflow-x-hidden h-[calc(100vh-60px)] bg-slate-700">
            <div className="w-full bg-white p-4 rounded-lg relative">
              <button
                className="absolute top-4 right-4 rounded-xl p-1 w-8 h-8 flex items-center justify-center border-2 border-black font-bold text-sm hover:bg-black hover:text-white"
                onClick={(e) => closeResult(e)}
              >
                x
              </button>
              <h2 className="font-bold text-lg">
                Zoekresultaat voor {searchText}
              </h2>
              {searchResult.vehicle ? (
                <div>
                  <p className="text-xs mt-2">
                    Voertuig gevonden met nummerplaat{' '}
                    {searchResult.vehicle.licensePlate}
                  </p>
                </div>
              ) : null}
              {searchResult.location?.fullAddress ? (
                <div>
                  <p className="text-xs mt-2">
                    Laatst gekende locatie via de app{' '}
                    {searchResult.location.fullAddress}
                  </p>
                </div>
              ) : null}
              {searchResult.tickets?.length ? (
                <div>
                  <h4 className="searchtitle">Tickets</h4>
                  <TicketList
                    tickets={searchResult.tickets}
                    onReload={() => doSearch()}
                  />
                </div>
              ) : null}
              {searchResult.standby?.length ? (
                <div>
                  <h4 className="searchtitle">Beschikbaarheden</h4>
                  <AvailablesList availables={searchResult.standby} />
                </div>
              ) : null}
              {searchResult.planning?.length ? (
                <div>
                  <h4 className="searchtitle">Planning</h4>
                  <PlanningList planning={searchResult.planning} />
                </div>
              ) : null}
              {searchResult.logs?.length ? (
                <div>
                  <h4 className="searchtitle">Logs</h4>
                  <LogList logs={searchResult.logs} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SearchBar;
