/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { oneofficeAppURI } from '../config';
import { useAuth } from '../hooks/useAuth';
import { getUserForToken } from '../token';
import Spinner from '../components/Spinner';
import { PlusIcon } from '@heroicons/react/24/solid';

const MessageForm = ({ close, forceReload }) => {
  const [newMessage, setNewMessage] = useState({});
  const [fetching, setFetching] = useState();
  const [sendingMessage, setSendingMessage] = useState();
  const [drivers, setDrivers] = useState([]);
  const [driversPlannedToday, setDriversPlannedToday] = useState([]);
  const [driversPlanned, setDriversPlanned] = useState([]);
  const [specificDrivers, setSpecificDrivers] = useState([]);
  const [newDriver, setNewDriver] = useState(undefined);
  const { jwt } = useAuth();
  const user = jwt ? getUserForToken(jwt) : null;

  const updateMessage = (prop, val) => {
    setNewMessage({
      ...newMessage,
      [prop]: val,
    });
  };

  const sendNewMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.destination?.length) {
      return toast.error('Geen bestemmeling gekozen');
    }
    setSendingMessage(true);
    let recipients = [];
    switch (newMessage.destination) {
      case 'plannedtoday':
        recipients = driversPlannedToday;
        break;
      case 'planned':
        recipients = driversPlanned;
        break;
      case 'all':
        recipients = drivers;
        break;
      case 'specific':
        recipients = specificDrivers;
        break;
      default:
        recipients = drivers;
        break;
    }
    const res = await fetch(`${oneofficeAppURI}sendmessage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: { ...newMessage },
        recipients,
        user,
      }),
    });
    setSendingMessage(false);
    const data = await res.json();
    if (data?.success) {
      toast.success('Bericht verstuurd');
      setNewMessage({});
      close(true);
    } else {
      toast.error('Probleem bij sturen bericht.');
    }
  };

  const resetNewMessage = (e) => {
    e.preventDefault();
    setNewMessage({});
    close();
  };

  const loadDrivers = async () => {
    setFetching(true);
    const res = await fetch(`${oneofficeAppURI}drivergsm`);
    setFetching(false);
    const data = await res.json();
    if (data?.success) {
      setDrivers(data.drivers);
      let planned = [];
      let plannedToday = [];
      data.drivers?.forEach((driver) => {
        if (driver.planned) {
          planned.push(driver);
          if (driver.plannedToday) {
            plannedToday.push(driver);
          }
        }
      });
      setDriversPlanned(planned);
      setDriversPlannedToday(plannedToday);
    }
  };

  const addSpecificDriver = (e) => {
    e.preventDefault();
    if (!newDriver) {
      return;
    }
    let theDriver;
    for (let d = 0; d < drivers?.length; d++) {
      if (drivers[d]._id === newDriver) {
        theDriver = JSON.parse(JSON.stringify(drivers[d]));
      }
    }
    if (!specificDrivers?.length) {
      setSpecificDrivers([theDriver]);
    } else {
      let found = false;
      specificDrivers.forEach((sDriver) => {
        if (sDriver._id === theDriver._id) {
          found = true;
        }
      });
      if (found) return toast.error('Chauffeur is reeds aan lijst toegevoegd');
      setSpecificDrivers(specificDrivers.concat([theDriver]));
    }
  };

  useEffect(() => {
    loadDrivers();
  }, []);

  return (
    <form className="appform flex flex-col w-full" onSubmit={sendNewMessage}>
      {fetching || sendingMessage ? <Spinner /> : null}
      {sendingMessage ? (
        <p className="font-bold">Bezig met verzenden...</p>
      ) : (
        <div className="flex flex-row w-full">
          <div className={`flex flex-col w-[100%] lg:w-[100%]`}>
            <label>Bericht (max 160 tekens)</label>
            <textarea
              value={newMessage.text || ''}
              onChange={(e) => updateMessage('text', e.currentTarget.value)}
              rows={8}
              required
              maxLength={160}
              className="p-2"
            ></textarea>
            <p className={`text-xs text-green-600 mb-2`}>
              Resterend aantal tekens:{' '}
              {160 - parseInt(newMessage.text?.length || 0)}
            </p>
            <label>Bestemmeling</label>
            <select
              className="appselect"
              value={newMessage.destination || ''}
              required
              onChange={(e) =>
                updateMessage('destination', e.currentTarget.value)
              }
            >
              <option value="">Selecteer bestemming</option>
              <option value="plannedtoday">
                Chauffeurs momenteel in dienst (
                {driversPlannedToday?.length || 0}
                p)
              </option>
              <option value="planned">
                Chauffeurs deze week van dienst ({driversPlanned?.length || 0}p)
              </option>
              <option value="all">
                Alle chauffeurs ({drivers?.length || 0}p)
              </option>
              <option value="specific">Specifieke chauffeur(s)</option>
            </select>
            {newMessage.destination === 'specific' ? (
              <div className="flex flex-col">
                <label>
                  Geselecteerde ontvangers{' '}
                  {specificDrivers?.length
                    ? `(${specificDrivers?.length})`
                    : null}
                </label>
                {specificDrivers?.length ? (
                  <table
                    className={`text-xs border apptable mt-0`}
                    width="100%"
                  >
                    <tbody>
                      {specificDrivers.map((specificDriver, s) => (
                        <tr className="font-bold bg-gray-300" key={s}>
                          <td>
                            {s + 1}. {specificDriver.alias}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>Geen specifieke chauffeurs geselecteerd</p>
                )}
                <div className="mt-2 flex flex-row items-start justify-start">
                  <select
                    className="appselect"
                    value={newDriver || ''}
                    onChange={(e) => setNewDriver(e.currentTarget.value)}
                  >
                    <option value="">Selecteer chauffeur en voeg toe</option>
                    {drivers?.map((driver, d) => (
                      <option value={driver._id} key={d}>
                        {driver.alias}
                      </option>
                    ))}
                  </select>
                  <button
                    className="bg-blue-400 text-white ml-2 w-8 h-8"
                    onClick={addSpecificDriver}
                    disabled={!newDriver}
                  >
                    <PlusIcon />
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}

      {!sendingMessage &&
      newMessage.destination?.length &&
      (newMessage.destination !== 'specific' || specificDrivers?.length) ? (
        <button
          type="submit"
          className="mt-1 rounded-sm bg-green-400 text-white p-2 pl-4 pr-4 w-full"
        >
          Verstuur bericht naar{' '}
          {newMessage.destination === 'specific' ? (
            <span>{specificDrivers?.length}</span>
          ) : null}
          {newMessage.destination === 'plannedtoday' ? (
            <span>{driversPlannedToday?.length}</span>
          ) : null}
          {newMessage.destination === 'planned' ? (
            <span>{driversPlanned?.length}</span>
          ) : null}
          {newMessage.destination === 'all' ? (
            <span>{drivers?.length}</span>
          ) : null}{' '}
          chauffeur(s)
        </button>
      ) : null}
      <button
        className="mt-1 rounded-sm bg-gray-400 text-white p-2 pl-4 pr-4 w-full"
        onClick={(e) => resetNewMessage(e)}
        disabled={sendingMessage}
      >
        Annuleren
      </button>
    </form>
  );
};

export default MessageForm;
