import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import SignIn from './views/SignIn';
import { ProtectedRoute } from './ProtectedRoute';
import { SideBarProvider } from './hooks/useSideBar';
import { SearchProvider } from './hooks/useSearch';
import { AuthProvider } from './hooks/useAuth';
import { RecentItemProvider } from './hooks/useRecentItems';
import PageLayout from './components/PageLayout';
import DashBoard from './views/Dashboard';
import Planning from './views/Planning';
import Logs from './views/Logs';
import Availabilities from './views/Availabilities';
import AvailabilitiesPerDriver from './views/AvailabilitiesPerDriver';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { nlBE } from 'date-fns/locale';
import SignOut from './views/SignOut';
import Tickets from './views/Tickets';
import Messages from './views/Messages';
import Remarks from './views/Remarks';

setDefaultOptions({ locale: nlBE });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 8000,
        }}
      />
      <AuthProvider>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signout" element={<SignOut />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <SideBarProvider>
                  <SearchProvider>
                    <RecentItemProvider>
                      <PageLayout />
                    </RecentItemProvider>
                  </SearchProvider>
                </SideBarProvider>
              </ProtectedRoute>
            }
          >
            <Route index element={<DashBoard />} />
            <Route path="/planning" element={<Planning />} />
            <Route path="/availability" element={<Availabilities />} />
            <Route
              path="/availabilityday"
              element={<AvailabilitiesPerDriver />}
            />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/remarks" element={<Remarks />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
