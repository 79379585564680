import { useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';

const SignOut = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  });
  return null;
};

export default SignOut;
