import { useEffect, useState } from 'react';
import { oneofficeAppURI } from '../config';
import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from '@heroicons/react/24/solid';
import Spinner from '../components/Spinner';
import { useSideBar } from '../hooks/useSideBar';
import TicketForm from './TicketForm';
import TicketList from '../components/TicketList';

const Tickets = () => {
  const [tickets, setTickets] = useState();
  const [fetching, setFetching] = useState();
  const [search, setSearch] = useState({});
  const { showRightSideBar, hideRightSideBar } = useSideBar();

  const loadTickets = async () => {
    setFetching(true);
    //setShowNew(false);
    const res = await fetch(`${oneofficeAppURI}tickets`);
    setFetching(false);
    const data = await res.json();
    if (data?.tickets) {
      setTickets(data.tickets);
    }
  };

  const searchTickets = async () => {
    setFetching(true);
    //setShowNew(false);
    const res = await fetch({
      method: 'POST',
      url: `${oneofficeAppURI}tickets/search`,
      body: JSON.stringify({
        search,
      }),
    });
    setFetching(false);
    const data = await res.json();
    if (data?.tickets) {
      setTickets(data.tickets);
    }
  };

  const resetSearch = (e) => {
    e.preventDefault();
    setSearch({});
    //setShowSearch(false);
  };

  const showNewTicket = () => {
    showRightSideBar(
      <TicketForm
        close={(reload) => {
          hideRightSideBar();
          if (reload) {
            loadTickets();
          }
        }}
      />
    );
  };

  useEffect(() => {
    loadTickets();
  }, []);

  return (
    <div className="p-4 overflow-scroll overflow-x-hidden h-[calc(100vh-60px)]">
      {fetching ? <Spinner /> : null}
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Loodswezen Tickets</h1>
        <div>
          <button
            onClick={() => showNewTicket()}
            className="bg-green-400 text-xs p-2 rounded-md mr-2"
          >
            <PlusIcon className="w-4 h-4 float-left mr-2" /> Nieuw ticket
          </button>
          {false && (
            <button
              onClick={() => null}
              className="bg-secondary-400 text-xs p-2 rounded-md mr-2"
            >
              <MagnifyingGlassIcon className="w-4 h-4 float-left mr-2" /> Zoeken
            </button>
          )}
          <button
            onClick={() => loadTickets()}
            className="bg-primary-400 text-xs p-2 rounded-md"
          >
            <ArrowPathIcon className="w-4 h-4 float-left mr-2" /> Lijst
            vernieuwen
          </button>
        </div>
      </div>

      <TicketList tickets={tickets} onReload={loadTickets} />
    </div>
  );
};

export default Tickets;
