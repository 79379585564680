/* eslint-disable react-hooks/exhaustive-deps */
import * as rdd from 'react-device-detect';
import { createContext, useContext, useMemo, useState } from 'react';
const SideBarContext = createContext();

export const SideBarProvider = ({ children }) => {
  const [sideBarWidth, setSideBarWidth] = useState(
    rdd.isMobile ? window.screen.width : 240
  );
  const [sideBarOpen, setSideBarOpen] = useState(rdd.isMobile ? false : true);
  const [rightSideBarOpen, setRightSideBarOpen] = useState(false);
  const [rightSideBarContent, setRightSideBarContent] = useState(false);
  const [rightSideBarExpanded, setRightSideBarExpanded] = useState(false);

  // call this function when you want the sidebar to open/close
  const updateSideBarWidth = (width) => {
    setSideBarWidth(width);
  };

  // call this function when you want the sidebar to open/close
  const toggleSidebar = () => {
    setSideBarOpen(!sideBarOpen);
    setSideBarWidth(sideBarOpen ? 66 : 240);
  };

  const showRightSideBar = (content, expand) => {
    setRightSideBarOpen(true);
    setRightSideBarExpanded(expand || false);
    setRightSideBarContent(content || null);
  };

  const hideRightSideBar = () => {
    setRightSideBarOpen(false);
    setRightSideBarContent(undefined);
  };

  const value = useMemo(
    () => ({
      sideBarOpen,
      sideBarWidth,
      rightSideBarExpanded,
      rightSideBarOpen,
      rightSideBarContent,
      updateSideBarWidth,
      toggleSidebar,
      showRightSideBar,
      hideRightSideBar,
    }),
    [
      sideBarOpen,
      sideBarWidth,
      rightSideBarOpen,
      rightSideBarExpanded,
      rightSideBarContent,
    ]
  );
  return (
    <SideBarContext.Provider value={value}>{children}</SideBarContext.Provider>
  );
};

export const useSideBar = () => {
  return useContext(SideBarContext);
};
