import { format } from 'date-fns';

const RemarkList = ({ remarks }) => {
  return (
    <table className="text-xs border mt-4 apptable" width="100%">
      <thead>
        <tr className="font-bold bg-gray-300">
          <td>Toegevoegd</td>
          <td>Datum</td>
          <td>Beschikbaar van - tot</td>
          <td>Nummerplaat</td>
          <td>Chauffeur</td>
          <td>Opmerking</td>
        </tr>
      </thead>
      <tbody>
        {remarks?.map((remarkItem, p) => (
          <tr key={p}>
            <td>{format(remarkItem.created, 'dd/MM/yyyy HH:mm')}</td>
            <td>{format(remarkItem.execDateUTC, 'EEEE dd/MM')}</td>
            <td>{remarkItem.pickupTime}</td>
            <td>
              <span style={{ display: 'flex' }}>
                {remarkItem.vehicle.licensePlate}
              </span>
            </td>
            <td>{remarkItem.driver.alias}</td>
            <td>{remarkItem.reason}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RemarkList;
